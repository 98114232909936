import {
  Page,
  PasswordResetConfirm,
  ProfileChange,
  ProfileDetail,
} from "frontend-common";
import constants from "@/constants";
import Home from "@/views/Home.vue";
import Router from "vue-router";
import Vue from "vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior(to, from) {
    if (to.path === from.path) {
      return;
    }
    return { x: 0, y: 0 };
  },
  base: constants.vueBaseUrl,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { title: "Domov" },
    },

    {
      path: "/opravovanie",
      name: "evaluation-overview",
      component: () =>
        import(
          /* webpackChunkName: "evaluation-overview" */ "./views/EvaluationOverview.vue"
        ),
      meta: { title: "Opravovanie" },
    },
    {
      path: "/opravovanie/:problemId",
      name: "evaluation",
      component: () =>
        import(/* webpackChunkName: "evaluation" */ "./views/Evaluation.vue"),
      props: (route) => ({ problemId: Number(route.params.problemId) }),
      meta: { title: "Opravovanie" },
    },
    {
      path: "/prihlasovanie/opravovanie/:roundId?",
      name: "evaluators",
      component: () =>
        import(/* webpackChunkName: "evaluators" */ "./views/Evaluators.vue"),
      props: (route) => ({ roundId: Number(route.params.roundId) || null }),
      meta: { title: "Prihlasovanie na opravovanie" },
    },
    {
      path: "/prihlasovanie/opravovanie/:roundId/:roundId2",
      name: "evaluators2",
      component: () =>
        import(/* webpackChunkName: "evaluators" */ "./views/Evaluators.vue"),
      props: (route) => ({
        roundId: Number(route.params.roundId),
        roundId2: Number(route.params.roundId2),
      }),
      meta: { title: "Prihlasovanie na opravovanie" },
    },
    {
      path: "/ulohy",
      name: "booklets-overview",
      component: () =>
        import(
          /* webpackChunkName: "booklet-overview" */ "./views/BookletOverview.vue"
        ),
      meta: { title: "Úprava zadaní" },
    },
    {
      path: "/ulohy/:bookletId",
      name: "booklet-change",
      component: () =>
        import(
          /* webpackChunkName: "booklet-change" */ "./views/BookletChange.vue"
        ),
      props: (route) => ({ bookletId: Number(route.params.bookletId) }),
      meta: { title: "Úprava zadaní" },
    },
    {
      path: "/ulohy/zadanie/:problemId",
      name: "statement-change",
      component: () =>
        import(
          /* webpackChunkName: "statement-change" */ "./views/ProblemChange.vue"
        ),
      props: (route) => ({
        problemId: Number(route.params.problemId),
        statement: true,
      }),
      meta: { title: "Úprava zadaní" },
    },
    {
      path: "/ulohy/:bookletId/rozpravka/:blockId",
      name: "textblock-change",
      component: () =>
        import(
          /* webpackChunkName: "textblock-change" */ "./views/BookletTextBlockChange.vue"
        ),
      props: (route) => ({
        blockId: Number(route.params.blockId),
        bookletId: Number(route.params.bookletId),
      }),
      meta: { title: "Úprava textového bloku" },
    },
    {
      path: "/ulohy/riesenie/:problemId",
      name: "solution-change",
      component: () =>
        import(
          /* webpackChunkName: "solution-change" */ "./views/ProblemChange.vue"
        ),
      props: (route) => ({ problemId: Number(route.params.problemId) }),
      meta: { title: "Úprava vzoráku" },
    },
    {
      path: "/profil",
      name: "profile-detail",
      component: ProfileDetail,
      meta: { title: "Môj profil" },
    },
    {
      path: "/profil/zmena",
      name: "profile-change",
      component: ProfileChange,
      meta: { title: "Zmena profilu" },
    },
    {
      path: "/pokazit",
      name: "broken-mode",
      component: () =>
        import(
          /* webpackChunkName: "broken-mode" */ "./easter-eggs/BrokenMode.vue"
        ),
      meta: { title: "Pokaziť stránku" },
    },
    {
      path: "/taiga",
      name: "taiga",
      component: () => import("./views/Taiga.vue"),
    },
    {
      path: "/obnova-hesla/:uid/:token",
      name: "password-reset-confirm",
      component: PasswordResetConfirm,
      props: (route) => ({ uid: route.params.uid, token: route.params.token }),
      meta: { title: "Obnova hesla" },
    },
    {
      path: "/s/:pageSlug",
      name: "page",
      component: Page,
      props: (route) => ({ pageSlug: route.params.pageSlug }),
    },
    {
      path: "/:pageSlug",
      redirect: { name: "page" },
    },
    {
      path: "*",
      name: "not-found",
      component: Page,
      props: () => ({ pageSlug: "" }),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name === "home") {
    next();
    return;
  }

  // Check if a token is stored
  if (!localStorage.getItem("token")) {
    next({ name: "home" });
    return;
  }

  // If we have token and not the state we just let the user in,
  // because there is no way to tell if the user is organizer
  if (!router.app.stateLoaded) {
    next();
    return;
  }

  const allow =
    router.app.isAuthenticated && !router.app?.state?.user?.competitor;
  if (allow) {
    next();
    return;
  }

  next({ name: "home" });
});

export default router;

<!-- It's snowing emojis! Similar to old.fks.sk -->
<template>
  <div v-if="running" id="flake-container" />
</template>

<script>
export default {
  name: "SnowingEmojis",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      running: false,
      scheduler: null,
      tick: null,
    };
  },
  computed: {},
  mounted() {
    this.scheduleStart();
  },
  beforeDestroy() {
    this.stop();
    clearInterval(this.scheduler);
  },
  methods: {
    scheduleStart() {
      // for testing purposes
      // this.start()
      const startHour = 2;
      const endHour = 6;

      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const hour = now.getHours();
      let startTime = new Date(year, month, day + 1, startHour, 0, 0, 0);
      if (startHour <= hour && hour < endHour) {
        startTime = now;
      }
      this.scheduler = setTimeout(() => {
        this.start();
        this.scheduler = setTimeout(
          () => {
            this.stop();
            this.scheduleStart();
          },
          (endHour - startHour) * 60 * 60 * 1000,
        );
      }, startTime - now);
    },
    start() {
      this.running = true;
      this.$nextTick(() => {
        init();
        this.tick = setInterval(update, 1000 / fps);
      });
    },
    stop() {
      this.running = false;
      clearInterval(this.tick);
    },
  },
};

// the actual snowing code is outside of Vue, because Vue adds performance overhead and reduces the
// animation smoothness

const fps = 30;
const flakeCount = 16;
const maxStartDx = 0.0006;
const maxChangeDx = 0.00008;
const minStartDy = 0.003;
const maxStartDy = 0.005;

let flakes = [];

function init() {
  flakes = [];
  for (let i = 0; i < flakeCount; i++) {
    const flake = newFlake();
    const element = document.createElement("span");
    element.innerHTML = flake.text;
    element.classList.add("flake");
    document.getElementById("flake-container").appendChild(element);
    flake.element = element;
    flakes.push(flake);
  }
}

function newFlake() {
  const emojis = ["🐰", "🍮", "🌱", "🚂"];
  const flake = {
    x: Math.random(),
    y: Math.random(),
    dx: (Math.random() - 0.5) * 2 * maxStartDx,
    dy: minStartDy + Math.random() * (maxStartDy - minStartDy),
    text: emojis[Math.floor(Math.random() * emojis.length)],
  };
  return flake;
}

function update() {
  for (const flake of flakes) {
    flake.dx += (Math.random() - 0.5) * 2 * maxChangeDx;
    flake.x += flake.dx;
    flake.y += flake.dy;
    if (flake.x < 0 || flake.x > 1 || flake.y > 1) {
      Object.assign(flake, newFlake());
      flake.y = -0.1;
    }
    flake.element.style.left = `${96 * flake.x}%`;
    flake.element.style.top = `${100 * flake.y}%`;
  }
}
</script>

<style>
.flake {
  position: absolute;
  z-index: 999999;
  font-size: 300%;
}
</style>

<template>
  <div class="mt-5 mb-3">
    <b-container class="text-center text-primary">
      <h1>Vitaj, vedúci!</h1>
      <h2 v-if="!$root.isAuthenticated">
        Začni tým, že sa
        <b-link v-b-modal.login-modal class="text-secondary">
          <!-- eslint-disable-next-line vue/multiline-html-element-content-newline -->
          prihlásiš</b-link
        >.
      </h2>
      <b-img
        class="mt-3"
        src="/lsp.webp"
        @click="(e) => clickHandler(e, $router)"
      />
    </b-container>
  </div>
</template>

<script>
import { clickHandler } from "../easter-eggs/imageClickZone";

export default {
  name: "Home",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    clickHandler,
  },
};
</script>

<style scoped></style>

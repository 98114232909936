import { render, staticRenderFns } from "./RegisterStepTwo.vue?vue&type=template&id=2289def6&scoped=true&"
import script from "./RegisterStepTwo.vue?vue&type=script&lang=js&"
export * from "./RegisterStepTwo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend-internal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2289def6",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BCol, BRow, BFormRow, BFormGroup, BForm} from 'bootstrap-vue'
    installComponents(component, {BButton, BCol, BRow, BFormRow, BFormGroup, BForm})
    

export default component.exports
<template>
  <div id="app">
    <MainNavbar class="main-navbar" />

    <b-row no-gutters style="flex-wrap: nowrap">
      <b-col
        v-if="$root.isAuthenticated"
        cols="auto"
        class="border-right bg-light d-none d-lg-inline"
      >
        <SideMenu
          :class="[
            'sticky-below-navbar sidebar overflow-auto text-nowrap',
            $root.storage.menuCollapsed ? '' : 'sidebar-full-width',
          ]"
          :collapsed="$root.storage.menuCollapsed"
          @toggle-collapsed="
            $root.storage.menuCollapsed = !$root.storage.menuCollapsed
          "
        />
      </b-col>
      <b-col>
        <router-view />
      </b-col>
    </b-row>

    <LoginModal />
    <SingleToast />
    <WalkingPenguin />
    <SnowingEmojis />
  </div>
</template>

<script>
import { SideMenu, SingleToast } from "frontend-common";
import LoginModal from "@/components/LoginModal.vue";
import MainNavbar from "@/components/MainNavbar.vue";
import SnowingEmojis from "@/easter-eggs/SnowingEmojis.vue";
import WalkingPenguin from "@/easter-eggs/WalkingPenguin.vue";

export default {
  name: "App",
  components: {
    LoginModal,
    MainNavbar,
    WalkingPenguin,
    SideMenu,
    SingleToast,
    SnowingEmojis,
  },
  mixins: [],
  props: {},
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "frontend-common/themes/base-app.scss";
</style>

<!-- A penguin with a nightcap walks across the screen at midnight. -->
<template>
  <transition name="go">
    <img v-if="go" class="penguin" src="https://i.imgur.com/lkcElNE.gif" />
  </transition>
</template>

<script>
export default {
  name: "WalkingPenguin",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      go: false,
      interval: null,
    };
  },
  computed: {},
  mounted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();
    // for testing purposes
    // const startTime = new Date(
    //   year,
    //   month,
    //   day,
    //   now.getHours(),
    //   now.getMinutes(),
    //   now.getSeconds() + 5,
    //   0,
    // )
    const startTime = new Date(year, month, day + 1, 0, 0, 0, 0);
    setTimeout(() => {
      this.run();
      this.interval = setInterval(this.run, 24 * 60 * 60 * 1000);
    }, startTime - now);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    run() {
      this.go = true;
      setTimeout(() => (this.go = false), 10000);
    },
  },
};
</script>

<style scoped>
.penguin {
  position: fixed;
  bottom: 0px;
  width: 350px;
  height: 350px;
  z-index: 999999;
  left: 100%;
}

.go-enter-active {
  transition: left 10s linear;
}

.go-enter-to {
  left: -350px;
}
</style>

import { render, staticRenderFns } from "./LoginForm.vue?vue&type=template&id=f0486698&"
import script from "./LoginForm.vue?vue&type=script&lang=js&"
export * from "./LoginForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend-internal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BImg, BCol, BLink, BButton, BRow} from 'bootstrap-vue'
    installComponents(component, {BImg, BCol, BLink, BButton, BRow})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBModal} from 'bootstrap-vue'
    installDirectives(component, {'b-modal': VBModal})
    

export default component.exports
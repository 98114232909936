import { render, staticRenderFns } from "./ProfileChange.vue?vue&type=template&id=228786d2&scoped=true&"
import script from "./ProfileChange.vue?vue&type=script&lang=js&"
export * from "./ProfileChange.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../frontend-internal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228786d2",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BCol, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BButton, BCol, BRow, BContainer})
    

export default component.exports
<!-- The lightbulb winks periodically and spins on hover. -->
<template>
  <img
    :src="`/logos/pmat-no-margin${wink ? '-wink' : ''}.svg`"
    alt="P-MAT"
    class="rotating"
  />
</template>

<script>
export default {
  name: "WinkingLogo",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      wink: false,
      interval: null,
    };
  },
  computed: {},
  mounted() {
    this.interval = setInterval(() => {
      this.wink = true;
      setTimeout(() => (this.wink = false), 400);
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {},
};
</script>

<style scoped>
.rotating {
  transition: transform 0.4s ease-in-out;
  transform-origin: 50% 45%;
}

.rotating:hover {
  transform: rotateZ(360deg);
}
</style>

import { render, staticRenderFns } from "./PasswordResetConfirm.vue?vue&type=template&id=c73dd8fc&scoped=true&"
import script from "./PasswordResetConfirm.vue?vue&type=script&lang=js&"
export * from "./PasswordResetConfirm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../frontend-internal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c73dd8fc",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BContainer, BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BContainer, BCol, BRow})
    

export default component.exports
// Target settings
const zones = [
  { x: { min: 0.149, max: 0.168 }, y: { min: 0.539, max: 0.598 } },
  { x: { min: 0.257, max: 0.278 }, y: { min: 0.263, max: 0.314 } },
  { x: { min: 0.872, max: 0.89 }, y: { min: 0.224, max: 0.271 } },
];

export function clickHandler(e, router) {
  // Checks whether a click is in the specified sub-area of the target element.
  // If yes, the user is redirected to the BrokenMode page.
  const rect = e.target.getBoundingClientRect();
  const relX = (e.clientX - rect.left) / e.target.width;
  const relY = (e.clientY - rect.top) / e.target.height;

  zones.find(({ x, y }) => {
    if (x.min < relX && relX < x.max && y.min < relY && relY < y.max) {
      router.push({ name: "broken-mode" });
    }
  });
}

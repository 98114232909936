import {
  api,
  bootstrapPlugins,
  formulateSetup,
  iconList,
  modals,
  storage,
  toasts,
} from "frontend-common";
import constants from "@/constants";
import Vue from "vue";

// Bootstrap theme loading
if (constants.frontendDevTheme) {
  import("frontend-common/themes/dev.scss");
}
switch (constants.frontendTheme) {
  case "beta.internal":
    import("frontend-common/themes/beta.scss");
    import("@/themes/internal.scss");
    break;
  case "internal":
  default:
    import("@/themes/internal.scss");
}

// BootstrapVue
for (const plugin of bootstrapPlugins) Vue.use(plugin);

// VueFormulate
import VueFormulate from "@braid/vue-formulate";

Vue.use(VueFormulate, formulateSetup);

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(...iconList);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// VueScrollTo
import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

// Google Auth
Vue.use((vue) => {
  let resolvers = [];
  let rejecters = [];

  // Google SDK is loaded with script tag in `public/index.html`
  // eslint-disable-next-line no-undef
  const client = google.accounts.oauth2.initCodeClient({
    client_id: constants.googleClientId,
    scope: "profile email",
    callback: (response) => {
      if (response.code) {
        resolvers.forEach((resolve) => resolve(response.code));
        resolvers = [];
        rejecters = [];
      } else {
        rejecters.forEach((reject) => reject(response));
        resolvers = [];
        rejecters = [];
      }
    },
  });

  Object.defineProperties(vue.prototype, {
    $googleClient: {
      get: () => {
        return {
          loginPopup: () =>
            new Promise((resolve, reject) => {
              client.requestCode();
              resolvers.push(resolve);
              rejecters.push(reject);
            }),
        };
      },
    },
  });
});

// Moment JS i18n
import "moment/locale/sk";
import moment from "moment";

moment.updateLocale("sk", { relativeTime: { future: "o %s" } });

// KaTeX
import "../node_modules/katex/dist/katex.min.css";

import router from "@/router";

// Sentry error reporting
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

if (constants.enableSentry) {
  Sentry.init({
    dsn: constants.sentryDsn,
    integrations: [
      // This is a temporary fix to prevent stack overflow, see:
      // https://github.com/getsentry/sentry-javascript/issues/2957#issuecomment-713505111
      new Sentry.Integrations.TryCatch({ eventTarget: false }),
      new VueIntegration({ Vue, attachProps: true }),
    ],
    environment: constants.sentryEnv,
  });
}

// Vue
import App from "@/App.vue";

Vue.config.productionTip = false;

new Vue({
  router,
  mixins: [api, modals, storage, toasts],
  data() {
    return {};
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const page = to.meta.title ? `${to.meta.title} · ` : "";
        document.title = page + "Interné stránky P-MATu";
      },
    },
  },
  render: (h) => h(App),
}).$mount("#app");

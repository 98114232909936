import { render, staticRenderFns } from "./CheckOrCross.vue?vue&type=template&id=6f23d0cb&scoped=true&"
import script from "./CheckOrCross.vue?vue&type=script&lang=js&"
export * from "./CheckOrCross.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend-internal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f23d0cb",
  null
  
)

export default component.exports
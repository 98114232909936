import constants from "../constants";

export default {
  methods: {
    apiTaigaProjects() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: "/users/taiga/",
        cache: { maxAge: constants.maxAgeDefaults.mid },
      });
    },
    apiTaigaInviteMyself(projectId) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "/users/taiga/",
        data: { project_id: projectId },
      });
    },
  },
};

import { render, staticRenderFns } from "./SingleToast.vue?vue&type=template&id=72c28199&"
import script from "./SingleToast.vue?vue&type=script&lang=js&"
export * from "./SingleToast.vue?vue&type=script&lang=js&"
import style0 from "./SingleToast.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../frontend-internal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BToast} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BToast})
    

export default component.exports
import { constants } from "frontend-common";

export default {
  ...constants,

  // Start of build-time determined values (they use process.env)
  // This should be the only chunk of code using process.env

  // End of build-time determined values
};

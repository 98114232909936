<template>
  <b-modal
    id="login-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    body-class="p-0"
    :no-close-on-backdrop="tabIndex === 1"
    :no-close-on-esc="tabIndex === 1"
    @show="tabIndex = 0"
  >
    <b-card no-body>
      <b-tabs v-model="tabIndex" card fill>
        <b-tab title="Prihlásiť sa">
          <LoginForm
            @login-modal-close="$bvModal.hide('login-modal')"
            @switch-to-register="tabIndex = 1"
          />
        </b-tab>
        <b-tab title="Registrácia">
          <RegisterOrganizerForm
            @login-modal-close="$bvModal.hide('login-modal')"
          />
        </b-tab>

        <template v-slot:tabs-end>
          <b-button variant="light" @click="$bvModal.hide('login-modal')">
            <font-awesome-icon size="lg" :icon="['fas', 'times']" />
          </b-button>
        </template>
      </b-tabs>
    </b-card>
  </b-modal>
</template>

<script>
import { LoginForm, RegisterOrganizerForm } from "frontend-common";

export default {
  name: "LoginModal",
  components: {
    LoginForm,
    RegisterOrganizerForm,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
};
</script>

<style scoped></style>

import { render, staticRenderFns } from "./ResultsRowSum.vue?vue&type=template&id=1aaf1b4b&scoped=true&"
import script from "./ResultsRowSum.vue?vue&type=script&lang=js&"
export * from "./ResultsRowSum.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend-internal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aaf1b4b",
  null
  
)


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBPopover} from 'bootstrap-vue'
    installDirectives(component, {'b-popover': VBPopover})
    

export default component.exports